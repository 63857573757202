import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../components/font-awesome'
// import {
//   faCoffee,
// } from '@fortawesome/free-solid-svg-icons'

const IndexPage = () => (
  <Layout>
    <Seo title="Hochschulsportverband Niedersachsen/Bremen" />
  

    <section id="Willkommen" className="pt-10">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <StaticImage
              src="../images/map-neu-2024.png"
              // width={600}
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="Hochschulsportverbandes Niedersachsen/ Bremen"
              className="img-fluid"
            />
          </div>
          <div className="col-md-7 col-lg-6 order-md-1">
            <span className="badge badge-pill badge-primary-soft mb-3">
              <span className="h6 text-uppercase">Willkommen</span>
            </span>
            <h1 className="text-color display-7 text-md-left">
              Hochschulsportverband <br />
              <span className="text-primary">Niedersachsen/ Bremen </span>
            </h1>
            <p className="lead text-md-left text-muted mb-6 mb-lg-8">
              Der Hochschulsportverband Niedersachsen/ Bremen (HVNB) dient der Förderung des Hochschulsports in den beteiligten Ländern Niedersachsens und Bremen. Er nimmt gleichzeitig die Funktion der Landesvertretungen von Niedersachsen und Bremen im Rahmen des Dachverbandes des Hochschulsport, dem Allgemeinen Deutschen Hochschulsportverbands (adh), wahr.
            </p>
            <div className="d-flex">
              <div className="pe-4 pe-lg-5">
                <h3 className="text-primary mb-0">
                  12
                </h3>
                <p className="text-gray-700 mb-0">
                  Mitglieder
                </p>
              </div>
              <div className="border-left"></div>
              <div className="px-4 px-lg-5">
                <h3 className="text-primary mb-0">
                  19
                </h3>
                <p className="text-gray-700 mb-0">
                  Mitgliedshochschulen
                </p>
              </div>
              <div className="border-left"></div>
              <div className="ps-4 ps-lg-5">
                <h3 className="text-primary mb-0">
                  180.000
                </h3>
                <p className="text-gray-700 mb-0">
                  Studierende
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="Mitglieder" className="py-5 highlight">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <span className="badge badge-pill badge-primary-soft mb-3">
              <span className="h6 text-uppercase">Mitgliedshochschulen</span>
            </span>
            <h2>Die Mitgliedshochschulen des HVNB</h2>
            <p className="lead text-md-left text-muted mb-6 mb-lg-8">
              Der Hochschulsport fördert das soziale Leben an den Universitäten und Hochschule. Er ist ein wichtiges Element im Rahmen der Gesundheitsförderung und Persönlichkeitsentwicklung von Studierenden und Beschäftigten.
            </p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {/* {contentData.map((card) => {
            return <Card key={card.id} {...card}></Card>;
          })} */}
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Ostfalia Hochschulsport</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Ostfalia Hochschule für angewandte Wissenschaften</li>
                <li className="list-group-item">Hochschule Braunschweig/Wolfenbüttel</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.ostfalia.de/sport/" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochulsport Braunschweig</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Technische Universität Braunschweig</li>
                {/* <li className="list-group-item">Ostfalia Hochschule angewandte Wissenschaften</li> */}
              </ul>
              <div className="card-footer ">
                <a href="https://www.tu-braunschweig.de/" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Verein für Hochschulsport e.V. – Universität Bremen</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Universität Bremen</li>
                <li className="list-group-item">Jacobs-University Bremen</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.uni-bremen.de/hospo" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Sportinstitut der TU Clausthal</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Technische Universität Clausthal</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.sport.tu-clausthal.de/" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Emden/ Leer</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Hochschule Emden/ Leer</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.hs-emden-leer.de/hochschule/organisation/einrichtungen/health-sports" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Göttingen</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Georg-August-Universität Göttingen</li>
              </ul>
              <div className="card-footer ">
                <a href="https://my.sport.uni-goettingen.de" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Hildesheim</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Universität Hildesheim</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.uni-hildesheim.de/fb1/institute/institut-fuer-sportwissenschaft/hochschulsport/" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Hannover</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Leibniz Universität Hannover</li>
                <li className="list-group-item">Hochschule Hannover</li>
                <li className="list-group-item">Medizinische Hochschule Hannover</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.hochschulsport-hannover.de/" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Hannover</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Tierärztliche Hochschule Hannover</li>
                <li className="list-group-item">Hochschule für Musik, Theater und Medien Hannover</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.hochschulsport-hannover.de/" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Lüneburg</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Leuphana Universität Lüneburg</li>
                <li className="list-group-item">BA / VWA Lüneburg</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.leuphana.de/services/hochschulsport.html" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Oldenburg</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Carl von Ossietzky Universität Oldenburg</li>
              </ul>
              <div className="card-footer ">
                <a href="https://uol.de/hochschulsport" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Osnabrück</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Universität Osnabrück</li>
                <li className="list-group-item">Hochschule Osnabrück</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.zfh.uni-osnabrueck.de" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <div className="card-body">
                <h5 className="card-title">Hochschulsport Vechta</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Universität Vechta</li>
              </ul>
              <div className="card-footer ">
                <a href="https://www.uni-vechta.de/zentrum-fuer-hochschulsport" target="_blank" className="website">Hochschulsporteinrichtung <FontAwesomeIcon icon={'long-arrow-alt-right'} className="arrow" size="lg" /></a>
              </div>
            </div>
          </div>


        </div>
        </div>
      </section>
    <section id ="Vorstand" className="py-5 ">
      <div className="container">
        <span className="badge badge-pill badge-primary-soft mb-3">
          <span className="h6 text-uppercase">Vorstandsmitglieder</span>
        </span>
        <h2>Das Team hinter dem HNVB</h2>
        <p className="lead text-md-left text-muted mb-6 mb-lg-8">
          Der ehrenamtliche Vorstand des HVNB besteht aus sechs Personen, die mit verschiedenen Aufgaben betraut sind und von den HVNB-Mitgliedern gewählt werden.
        </p>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <StaticImage
                src="../images/fotos/heike.jpg"
                quality={100}
                height={270}
                formats={["AUTO", "WEBP"]}
                alt="Hochschulsportverbandes Niedersachsen/ Bremen"
                className="img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Heike Anders</h5>
                <p className="card-text">Vorstandsvorsitzende</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Uni Bremen</li>
                <li className="list-group-item">Mail: <a href={`mailto:handers@uni-bremen.de`}>handers@uni-bremen.de</a></li>
              </ul>
              <div className="card-footer ">
                
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <StaticImage
                src="../images/fotos/juri.jpg"
                height={270}
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="Hochschulsportverbandes Niedersachsen/ Bremen"
                className="img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Juri Ramke</h5>
                <p className="card-text">Stv. Vorstandsvorsitzender und Bildungsarbeit</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Uni Oldenburg</li>
                <li className="list-group-item">Mail: <a href={`mailto:jens.o.ramke@uol.de`}>jens.o.ramke@uol.de</a></li>
              </ul>
              <div className="card-footer ">
                
              </div>
            </div>
            </div>
            <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <StaticImage
                src="../images/fotos/dirk.jpg"
                height={270}
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="Hochschulsportverbandes Niedersachsen/ Bremen"
                className="img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Dirk Dödtman</h5>
                <p className="card-text">Studentischer Vertreter</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Uni Göttingen</li>
                <li className="list-group-item">Mail: <a href={`mailto:dirk.doedtmann@sport.uni-goettingen.de`}>dirk.doedtmann@sport.uni-goettingen.de</a></li>
              </ul>
              <div className="card-footer ">
                
              </div>
            </div>
            </div>
            <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <StaticImage
                src="../images/fotos/elke-zech.jpg"
                height={270}
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="Hochschulsportverbandes Niedersachsen/ Bremen"
                className="img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Elke Zech</h5>
                <p className="card-text">Finanzen und Verwaltung</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Uni Göttingen</li>
                <li className="list-group-item">Mail: <a href={`mailto:elke.zech@sport.uni-goettingen.de`}>elke.zech@sport.uni-goettingen.de</a></li>
              </ul>
              <div className="card-footer ">
                
              </div>
            </div>
            </div>
            <div className="col">
            <div className="card h-100 uni glow-shadow border-top">
              <StaticImage
                src="../images/fotos/stelin.jpg"
                quality={100}
                height={270}
                formats={["AUTO", "WEBP"]}
                alt="Hochschulsportverbandes Niedersachsen/ Bremen"
                className="img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Mathias Steiln</h5>
                <p className="card-text">Wettkampf- und Spitzensport</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Uni Braunschweig</li>
                <li className="list-group-item">Mail: <a href={`mailto:m.steiln@tu-braunschweig.de`}>m.steiln@tu-braunschweig.de</a></li>
              </ul>
              <div className="card-footer ">
                
              </div>
            </div>
            </div>
            <div className="col">
          </div>
        </div>
      </div>
    </section>
      <section id ="Satzung" className="py-5 highlight">
        <div className="container">
          <span className="badge badge-pill badge-primary-soft mb-3">
            <span className="h6 text-uppercase">Vereinssatzung</span>
          </span>
          <h2>Satzung des Hochschulsportverbandes Niedersachsen/ Bremen (HVNB)</h2>
          
            <ol className="list-group list-group-numbered">
              <li className="list-group-item">Aufgaben
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  Der HVNB dient der Förderung des Hochschulsports in den beteiligten Ländern Niedersachen und Bremen. Er nimmt gleichzeitig die Funktion der Landesvertretungen von Bremen und Niedersachsen im Rahmen des Allgemeinen Deutschen Hochschulsportverbands (adh) wahr.
                </p>
              </li>
              <li className="list-group-item">Mitglieder
                <ol className="list-group list-group-numbered">
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Mitglieder sind die Mitgliedshochschulen des Allgemeinen Deutschen Hochschulsportverbandes (adh) aus den Bundesländern Bremen und Niedersachsen.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Weitere Hochschulen aus diesen Ländern können auf Antrag Mitglied werden. Der Antrag bedarf einer 2/3-Mehrheit der Mitgliederversammlung.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Die Mitglieder werden vertreten durch je eine gewählte oder legitimierte Vertretung der Studierendenschaft und je eine Beauftragte/ einen Beauftragten der Hochschule bzw. der Institution, die für den Hochschulsport an diesem Standort zuständig ist.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="list-group-item">Organe
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  Der Verband hat folgende Organe:
                </p>
                <ol className="list-group list-group-numbered">
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      die Mitgliederversammlung
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      den Vorstand
                    </p>
                  </li>
                </ol>
              </li>
              <li className="list-group-item">Mitgliederversammlung
                <ol className="list-group list-group-numbered">
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Die Mitgliederversammlung ist das oberste Organ des Verbandes und entscheidet in allen Fragen, die sie für wichtig erachtet.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Die Mitgliederversammlung besteht aus den Vertretungen der Mitglieder gem. 2.3.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Ordentliche Mitgliederversammlungen finden in der Regel zweimal pro Jahr statt. Außerordentliche Mitgliederversammlungen sind einzuberufen, sofern dies von einem Drittel der Vertretungen gem. 2.3 verlangt wird.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Mitgliederversammlungen sind vom Vorstand einzuberufen unter Angabe der vorgesehenen Beratungspunkte (Tagesordnung) mit einer Frist von mindestens 2 Wochen. Ordnungsgemäß einberufene Mitgliederversammlungen sind in jedem Fall beschlussfähig.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Über die Sitzungen der Mitgliederversammlung wird ein Beschlussprotokoll geführt. Es gilt als genehmigt, wenn ihm nicht innerhalb von 2 Wochen ab Versand widersprochen wird. Über eventuelle Einsprüche entscheidet die folgende Mitgliederversammlung.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="list-group-item">Vorstand
                <ol className="list-group list-group-numbered">
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Der Vorstand führt die Geschäfte des Verbandes. Er ist an die Beschlüsse der Mitgliederversammlung gebunden.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Der Vorstand (möglichst zwei Studierende) besteht aus:
                    </p>
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item">
                        <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                          dem/der Vorstandsvorsitzenden
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                          dem Vorstand für Finanzen und Verwaltung
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                          dem Vorstand für Wettkampf- und Spitzensport
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                          dem Vorstand für Bildungsarbeit
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                          dem studentischen Vorstand für Engagementförderung und Vertretung studentischer Interessen
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Aus den Vorstandsmitgliedern (5.2.2 – 5.2.5) wählt der Vorstand die/den stellvertretenden Vorsitzende/n.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Der Vorstand wird von der Mitgliederversammlung für 2 Jahre gewählt. Wiederwahl ist möglich.
                    </p>
                  </li>
                  <li className="list-group-item">
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      Über die Sitzungen der Mitgliederversammlung wird ein Beschlussprotokoll geführt. Es gilt als genehmigt, wenn ihm nicht innerhalb von 2 Wochen ab Versand widersprochen wird. Über eventuelle Einsprüche entscheidet die folgende Mitgliederversammlung.
                    </p>
                  </li>
                </ol>
                <li className="list-group-item">
                  Sonstiges
                    <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                      In allen in dieser Satzung nicht geregelte Fragen gilt die Satzung bzw. Geschäftsordnung des adh sinngemäß.
                    </p>
                  </li>
              </li>
            </ol>
        </div>
      </section>

      <section id ="Kontakt" className="py-5">
        <div className="container">
          <span className="badge badge-pill badge-primary-soft mb-3">
            <span className="h6 text-uppercase">Kontakt</span>
          </span>
          <h2>Kontakt</h2>
          <div className="row">
            <div className="col">
              <h5>Hochschulsportverband Niedersachsen/Bremen</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                - Geschäftsstelle -<br />
                c/o Zentrum für Hochschulsport Hannover<br />
                Am Moritzwinkel 6<br />
                30167 Hannover<br />
                <a href="mailto:info@zfh.uni-hannover.de">info@zfh.uni-hannover.de</a>
              </p>
              
            </div>
            <div className="col">
              <h5>
                Der Datenschutzbeauftragte des Verantwortlichen ist:
              </h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Sebastian Knust<br />
                Zentrum für Hochschulsport Hannover<br />
                Am Moritzwinkel 6<br />
                30167 Hannover<br />
                Tel.: 0511 762 4964 <br />
                <a href="mailto:info@zfh.uni-hannover.de">knust@zfh.uni-hannover.de</a>
                
              </p>
            </div>
          </div>
        </div>


      </section>    
  </Layout>
)

export default IndexPage
